<template>
  <div class="pt-nav" style="padding-top: 50px;">
    <Header :title="title" class="header-nav" />

    <div class="address-box">
      <div class="box-title">
        <div>{{ $t('city.selectCity') }}</div>
        <div class="save-btn" @click="handleConfirm">{{ $t('common.sure') }}</div>
      </div>
      <AreaChoose ref="areChoose" :area-level="+areaLevel" :multiple="multiple" :max-count="+maxCount"
        @setDatas="setDatas" />

    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import AreaChoose from '../../components/AreaChoose.vue'
import bus from '../../utils/bus'
export default {
  name: '',
  components: { Header, AreaChoose },
  data() {
    return {
      addressKey: '',
      areaLevel: 0,
      multiple: false,
      maxCount: 3,
      addressData: '',
      title: '编辑资料'
    }
  },
  methods: {
    areaChange(addressData) {
      this.addressData = addressData
    },
    handleConfirm() {
      this.$refs.areChoose.getDatas()
    },
    setDatas(data) {
      if (!data.length) return this.$toast('请选择地址')
      this.addressData = this.multiple ? data : data[0]
      if (this.addressKey === 'loadResource' || this.addressKey === 'loadResourceForCity') {
        bus.$emit('getAreaData', this.addressKey, this.addressData)
      } else {
        bus.$emit('getSelectAreas', this.addressKey, this.addressData)
      }
      this.$router.go(-1)
    }
  },
  mounted() {
    this.areaLevel = this.$route.query.areaLevel || this.areaLevel
    this.multiple = JSON.parse(this.$route.query.multiple || this.multiple)
    this.maxCount = this.$route.query.maxCount || this.maxCount
    this.addressKey = this.$route.query.addressKey || undefined
    const titleTemp = this.$route.query.title || ''
    if (titleTemp) {
      this.title = titleTemp
    }
  }
}
</script>

<style scoped lang="scss">
.pt-nav {
  z-index: 999;
}

.address-box {
  width: calc(100% - 20px);
  margin: 10px auto;
  border-radius: 10px;
  background: #14151E;

  .box-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-size: 20px;
    font-weight: 600;

    .save-btn {
      height: 30px;
      border-radius: 5px;
      font-size: 13px;
      font-weight: 600;
      background: #8A73D0;
      width: 60px;
      line-height: 30px;
      text-align: center;
    }
  }
}

.header-nav {
  position: relative;

  .save-btn {
    position: absolute;
    right: 20px;
    bottom: 10px;
    height: 30px;
    border-radius: 5px;
  }
}
</style>
