<template>
  <div class="area-choose-wrap">
    <div class="hot-city-wrapper">
      <div class="title">{{ $t('city.selectedCity') }}</div>
      <div class="hot-city-list">
        <div class="hot-city light selected" v-for="item in selectCodes" :key="item"
          @click="handleAddSelect(item)">{{  areaLevel === 0 ? areaList.county_list[item] : areaList.city_list[item]}}</div>
      </div>
      <!-- <div class="dot-line" /> -->
    </div>
    <div class="hot-city-wrapper">
      <div class="title">{{ $t('city.hotCity') }}</div>
      <div class="hot-city-list">
        <div class="hot-city" :class="{light : isExistCity(item)}" v-for="item in hotCitys" :key="item"
          @click="(areaLevel === 0 ? handleChangeCity(item) : handleAddSelect(item))">{{areaList.city_list[item]}}</div>
      </div>
      <div class="dot-line" />
    </div>
    <div class="area-panel">
      <div class="province-warp">
        <div class="item title">{{ $t('city.province') }}</div>
        <div class="wrap-list">
          <div class="item" v-for="item in Object.keys(areaList.province_list)" :key="item"
            @click="handleChangeProvince(item)" :class="{light : isExistProvince(item)}">
            {{areaList.province_list[item]}}
          </div>
        </div>
      </div>
      <div class="province-warp">
        <div class="item title">{{ $t('city.city') }}</div>
        <div class="wrap-list">
          <div class="item" v-for="item in Object.keys(currentCityList)" :key="item"
            @click="(areaLevel === 0 ? handleChangeCity(item) : handleAddSelect(item))"
            :class="{light : isExistCity(item)}">
            {{currentCityList[item]}}
          </div>
        </div>
      </div>
      <div class="province-warp" v-if="(areaLevel === 0)">
        <div class="item title">{{ $t('city.area') }}</div>
        <div class="wrap-list">
          <div class="item" v-for="item in Object.keys(currentCountyList)" :key="item" @click="handleAddSelect(item)"
            :class="{light : selectCodes.includes(item)}">
            {{currentCountyList[item]}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { areaList } from '@/utils/areaData'
export default {
  name: '',
  props: {
    areaLevel: {
      type: Number,
      default: 1 // 0为区 1为市
    },
    multiple: {
      type: Boolean,
      default: true // true 为多选，false 单选
    },
    hotCitys: {
      type: Array,
      default: () => ['110100', '310100', '440300', '330100']
    },
    maxCount: {
      type: Number,
      default: 3 // 最多几个选项
    }
  },
  data() {
    return {
      areaList,
      loadTabNum: 1,
      currentProvince: '',
      currentCity: '',
      currentCityList: {}, // 当前市列表
      currentCountyList: {}, // 当前区列表
      selectCodes: [] // 选中code
    }
  },
  methods: {
    isExistCity(code) {
      const result = this.selectCodes.findIndex(item => {
        return parseInt(item / 100) * 100 === +code
      })
      return result > -1
    },

    isExistProvince(code) {
      const result = this.selectCodes.findIndex(item => {
        return parseInt(item / 1000) * 1000 === +code
      })
      return result > -1
    },

    handleChangeProvince(provinceCode) {
      this.currentProvince = this.areaList.province_list[provinceCode]
      this.currentCityList = {}
      for (const key in this.areaList.city_list) {
        if (parseInt(provinceCode / 10000) === parseInt(key / 10000)) {
          this.currentCityList[key] = this.areaList.city_list[key]
        }
      }
      this.handleChangeCity(Object.entries(this.currentCityList)[0][0])
    },

    handleChangeCity(cityCode) {
      this.currentCountyList = {}
      this.currentCity = this.areaList.city_list[cityCode]
      for (const key in this.areaList.county_list) {
        if (parseInt(cityCode / 100) === parseInt(key / 100)) {
          this.$set(this.currentCountyList, key, this.areaList.county_list[key])
        }
      }
    },
    handleAddSelect(code) {
      if (!this.multiple) {
        this.selectCodes = []
        this.$set(this.selectCodes, this.selectCodes.length, code)
        return
      }
      if (this.selectCodes.includes(code)) {
        this.selectCodes = this.remove(this.selectCodes, code)
      } else {
        if (this.selectCodes.length >= this.maxCount) {
          return this.$toast(`最多可以选择${this.maxCount}个地址哦～`)
        }
        this.$set(this.selectCodes, this.selectCodes.length, code)
      }
    },
    findKey(data, value, compare = (a, b) => a === b) {
      return Object.keys(data).find(k => compare(data[k], value))
    },
    getCity(code) {
      return parseInt(code / 100) * 100
    },
    getProvince(code) {
      return parseInt(code / 1000) * 1000
    },
    // 数组组合
    getDatas() {
      let list = []
      for (let key of this.selectCodes) {
        const cityCode = this.getCity(key)
        const provinceCode = this.getProvince(key)
        const city = {
          code: cityCode,
          name: this.areaList.city_list[cityCode]
        }
        const province = {
          code: provinceCode,
          name: this.areaList.province_list[provinceCode]
        }
        if (this.areaLevel === 0) {
          const area = {
            code: key,
            name: this.areaList.county_list[key]
          }
          list.push({
            province: province,
            city: city,
            area: area
          })
        } else {
          list.push({
            province: province,
            city: city
          })
        }
      }
      this.$emit('setDatas', list)
    },
    remove(data, val) {
      var index = data.indexOf(val);
      if (index > -1) {
        data.splice(index, 1);
        return data
      }
    }

  },
  mounted() {
    for (const key in this.areaList.city_list) {
      if (parseInt(key / 1000) === 110) {
        this.$set(this.currentCityList, key, this.areaList.city_list[key])
      }
    }
    for (const key in this.areaList.county_list) {
      if (parseInt(key / 100) === 1101) {
        this.$set(this.currentCountyList, key, this.areaList.county_list[key])
      }
    }
  }
}
</script>

<style scoped lang="scss">
.area-choose-wrap {
  width: 100%;
  min-height: 500px;
  padding-bottom: 20px;
  overflow-y: scroll;

  .hot-city-wrapper {
    padding: 10px 0;

    .title {
      padding: 0 10px;
      font-size: 14px;
      font-weight: 600;
    }

    .hot-city-list {
      display: flex;
      flex-wrap: wrap;
      min-height: 28px;
      margin-top: 10px;

      .hot-city {
        flex-shrink: 0;
        // margin: 10px 0 0 10px;
        margin-left: 10px;
        // border: 1px solid #fff;
        padding: 3px;
        // border-radius: 2px;
        font-size: 14px;
      }
    }

    .dot-line {
      margin-top: 10px;
      border: 1px dotted #3D3F51;
      width: calc(100% - 20px);
      margin-left: 10px;
    }
  }

  .light {
    // background: #828295;
    color: #FD2084;
  }

  .s20 {
    font-size: 20px !important;
  }
  .selected {
    color: #FD2084;
    background: rgba(253, 32, 132, 0.3);
    border: none !important;
    height: 28px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: 0 5px !important;
    border-radius: 4px;
    // // min-height: 420px;
    // margin-bottom: 20px;
  }

  .area-panel {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 20px);
    margin: auto;
    height: 440px;
    min-height: 440px;
    margin-bottom: 20px;
    overflow: hidden;
    // border: 1px solid #828295;
    border-radius: 4px;

    /* align-items: center; */
    .province-warp {
      flex: 1;
      height: 100%;
      // border-right: 1px solid #828295;
      position: relative;

      &:nth-child(2n + 1) {
        background: #14151E;
      }

      &:nth-child(2n + 2) {
        background: #14151E;
      }

      &:last-child {
        border-right: none;
      }

    }

    .wrap-list {
      height: 400px;
      overflow: auto;
    }

    .item {
      height: 36px;
      line-height: 36px;
      padding: 0 10px;
      cursor: pointer;

      &.title {
        background: #14151E;
        border-bottom: 1px solid #3D3F51;
        font-size: 14px;
        font-weight: 600;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
